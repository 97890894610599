import { Row } from 'antd'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/store/store'
import { getListLevel } from 'services/api/CommonApi'
import { formatPrice } from 'utils/ruleForm'
import * as S from './styled'
import { t } from 'i18next'

const IconStyle = { fontSize: '38px' }
const MemberRankPage: React.FC = () => {
  const [listRank, setListRank] = useState<Array<any>>([])
  const [currentLevelIdx, setCurrentLevelIdx] = useState<number>(0)
  const levels = [
    'AEONMALL@INTERN',
    'AEONMALL@STAFF',
    'AEONMALL@LEADER',
    'AEONMALL@MONITOR',
    'AEONMALL@MANAGER',
    'AEONMALL@DIRECTOR',
  ]
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const dispatch = useAppDispatch()

  const getData = async () => {
    try {
      const resData = (await getListLevel()).data
      const ownLevel = userInfo?.level

      const currentLevelData = resData.find((item: any) => item.key == ownLevel)
      let currentLvIdx =
        resData.indexOf(currentLevelData) == -1
          ? 0
          : resData.indexOf(currentLevelData)

      setListRank(resData)
      setCurrentLevelIdx(currentLvIdx)
      console.log(resData)
    } catch (err) {}
  }

  useEffect(() => {
    dispatch(getUserInfoAction())
    getData()
  }, [])

  return (
    <S.Container>
      <header className="header">
        <h3 className="title">Mức vốn đầu tư</h3>
        <p
          style={{
            fontSize: '16px',
          }}
        >
          Cấp độ cửa hàng hiện tại: AEON MALL@STAFF
        </p>
        <p className="sub-title">Số đơn hàng mỗi ngày: 60 Đơn hàng</p>
        <p className="description">
          Tham khảo cửa hàng phù hợp với số vốn bạn muốn đầu tư
        </p>
      </header>
      <Row style={{ marginTop: '16px' }}>
        {listRank.map((item, index) => {
          const isCurrent = index <= currentLevelIdx
          const isUpgradeable = index === currentLevelIdx + 1
          const isInvalid = index > currentLevelIdx && !isUpgradeable

          return (
            <S.OptionRankMember xs={24} md={24} key={index}>
              <S.OptionBlock>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div className="title-rank">
                    {item.price === 5000000
                      ? levels[0]
                      : item.price === 25000000
                      ? levels[1]
                      : item.price === 75000000
                      ? levels[2]
                      : levels[3]}
                  </div>
                  <span
                    style={{
                      color: isCurrent ? '#059669' : 'white',
                      backgroundColor: isCurrent ? '' : '#22c55e',
                      textAlign: 'center',
                      marginLeft: 'auto',
                      minWidth: '80px',
                      padding: '4px',
                      borderRadius: '0.375rem',
                    }}
                  >
                    {isCurrent ? t('active_mem') : t('wait_for_upgrade_mem')}
                  </span>
                </div>
                <div className="money">{formatPrice(item?.price)}</div>
                <hr style={{ borderTop: '1px solid #cccccc' }}></hr>
                <div className="content">
                  Số nhiệm vụ: {item?.order_quantity_per_day} đơn/ngày
                </div>
                <div className="content">
                  Tỷ lệ hoa hồng: {item?.commission_percent}%/đơn
                </div>
                <div className="content">
                  Thời gian hiệu lực của hội viên 365
                </div>
              </S.OptionBlock>
            </S.OptionRankMember>
          )
        })}
      </Row>
    </S.Container>
  )
}
export default MemberRankPage
