import { Col, Row, DatePicker, Button, Spin } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getListTransaction } from 'services/api/TransactionApi'
import { TRANSACTION_STATUS, TRANSACTION_TYPE } from 'utils/constants'
import { formatPrice } from 'utils/ruleForm'
import * as Styled from './styled'
import { t } from 'i18next'

const transactionType = {
  [TRANSACTION_TYPE.TOPUP]: {
    index: 0,
    title: 'Lịch sử nạp tiền',
    type: TRANSACTION_TYPE.TOPUP,
  },
  [TRANSACTION_TYPE.CASH_OUT]: {
    index: 1,
    title: 'Lịch sử rút tiền',
    type: TRANSACTION_TYPE.CASH_OUT,
  },
  [TRANSACTION_TYPE.PURCHASE_LEVEL]: {
    index: 2,
    title: 'Lịch sử nâng cấp VIP',
    type: TRANSACTION_TYPE.PURCHASE_LEVEL,
  },
}

const HistoryTransactionPage = () => {
  const [transactions, setTransactions] = useState<Array<any>>([])
  const [activeTab, setActiveTab] = useState(
    transactionType[TRANSACTION_TYPE.TOPUP]
  )
  const [isLoading, setIsLoading] = useState(false)
  const handleClickTab = async (tab: any) => {
    setActiveTab(tab)
    setIsLoading(true)
    const transactionData = (await getListTransaction({ type: tab.type })).data
    if (transactionData) {
      setIsLoading(false)
      setTransactions(transactionData)
    }
  }

  useEffect(() => {
    handleClickTab(transactionType[TRANSACTION_TYPE.TOPUP])
  }, [])
  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spin size="large" />
      </div>
    )
  }
  const renderTransactionItem = (item: any) => {
    return (
      <Styled.ItemWrapper>
        <Row>
          {t('history_transaction.id')}
          {': '}
          <b
            style={{
              marginLeft: 5,
              textTransform: 'uppercase',
            }}
          >
            {item._id}
          </b>
        </Row>
        <Row justify="space-between">
          <Col style={{ fontSize: 16 }}>
            {t('history_transaction.value')}:{' '}
            <span
              style={{
                color: item.type === TRANSACTION_TYPE.TOPUP ? 'green' : 'red',
              }}
            >
              {formatPrice(item.amount)}
            </span>
          </Col>
          <Col style={{ fontSize: 16, color: '#6b7280' }}>
            {t('history_transaction.time')}:{' '}
            {moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}
          </Col>
        </Row>
        {/* <Row justify="space-between">
          <Col style={{ fontSize: 16 }}>
            {transactionType} từ:{' '}
            {item?.meta?.user_bank?.bank_name ||
              item?.meta?.admin_topup_method?.bank_name}
          </Col>
        </Row> */}
        <Row justify="space-between">
          <Col style={{ fontSize: 16, color: 'white' }}>
            <span style={{ color: 'black' }}>
              {t('history_transaction.status')}:{' '}
            </span>{' '}
            <span
              style={{
                backgroundColor:
                  item.status === 'Pending'
                    ? 'orange'
                    : item.status === 'Reject'
                    ? 'red'
                    : 'green',
                color: 'white',
                padding: '3px 10px',
                borderRadius: '5px',
              }}
            >
              {item.status === 'Pending'
                ? 'Đang chờ duyệt'
                : item.status === 'Reject'
                ? 'Từ chối'
                : 'Hoàn Thành'}
            </span>
          </Col>
          {item.status === 'Reject' && (
            <Col style={{ fontSize: 16 }}>Lí do : {item.reject_reason}</Col>
          )}
        </Row>
      </Styled.ItemWrapper>
    )
  }

  return (
    <Styled.Container>
      <Styled.Title>{t('history_transaction.title')}</Styled.Title>
      <br />
      <Styled.ContainerTabs>
        <Styled.HeaderTabs>
          {/* {Object.values(transactionType).map((item, index) => (
            <Col
              key={index}
              span={24 / Object.keys(transactionType).length}
              className="title-tabs"
              onClick={() => {
                handleClickTab(item)
              }}
            >
              {item.title}
            </Col>
          ))} */}
          <Col
            span={24 / Object.keys(transactionType).length}
            className={`title-tabs ${
              activeTab.type === TRANSACTION_TYPE.TOPUP ? 'active' : ''
            }`}
            onClick={() =>
              handleClickTab(transactionType[TRANSACTION_TYPE.TOPUP])
            }
          >
            {t('history_transaction.deposit')}
          </Col>
          <Col
            span={24 / Object.keys(transactionType).length}
            className={`title-tabs ${
              activeTab.type === TRANSACTION_TYPE.CASH_OUT ? 'active' : ''
            }`}
            onClick={() =>
              handleClickTab(transactionType[TRANSACTION_TYPE.CASH_OUT])
            }
          >
            {t('history_transaction.withdraw')}
          </Col>
          <Col
            span={24 / Object.keys(transactionType).length}
            className={`title-tabs ${
              activeTab.type === TRANSACTION_TYPE.PURCHASE_LEVEL ? 'active' : ''
            }`}
            onClick={() =>
              handleClickTab(transactionType[TRANSACTION_TYPE.PURCHASE_LEVEL])
            }
          >
            {t('history_transaction.other')}
          </Col>
        </Styled.HeaderTabs>
      </Styled.ContainerTabs>
      {transactions.length ? (
        <div style={{ padding: '0px 10px', marginTop: 20 }}>
          {transactions.map(item => renderTransactionItem(item))}
        </div>
      ) : (
        <Styled.Empty>Trang này không có lịch sử</Styled.Empty>
      )}
    </Styled.Container>
  )
}
export default HistoryTransactionPage
