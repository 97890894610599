import { NotificationOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import R from 'assets'
import { t } from 'i18next'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/store/store'
import { formatPrice } from 'utils/ruleForm'
import { requestLevelCategory, requestLevelList } from './Api/LevelApi'
import CatalogAboutUs from './components/CatalogAboutUs'
import QuestHall from './components/QuestHall'
import QuickAction from './components/QuickAction'
import * as Styled from './styled'

const { Title, Paragraph, Text } = Typography
const HomePage: React.FC = () => {
  const [incomeList, setIncomeList] = useState<any>([])
  const [levelList, setLevelList] = useState<any>([])
  const [currentLevelIdx, setCurrentLevelIdx] = useState<number>(0)
  const [data, setData] = useState<any>([])

  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const dispatch = useAppDispatch()

  useEffect(() => {
    getLevelList()
    getRandomList()
    setInterval(() => {
      getRandomList()
    }, 5000)
  }, [])

  useEffect(() => {
    getLevelCategory()
  }, [levelList])

  const getLevelList = async () => {
    try {
      const resData = (await requestLevelList()).data
      const ownLevel = userInfo?.level

      const currentLevelData = resData.find((item: any) => item.key == ownLevel)
      let currentLvIdx =
        resData.indexOf(currentLevelData) == -1
          ? 0
          : resData.indexOf(currentLevelData)

      setLevelList([...resData])
      setCurrentLevelIdx(currentLvIdx)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getLevelCategory = async () => {
    try {
      let full_data = [...levelList]
      levelList?.forEach(async (item: any, index: number) => {
        const res = await requestLevelCategory(item.key)
        full_data[index].category = res.data.map((item: any) => {
          return item.name
        })
      })

      setData(full_data)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getRandomList = () => {
    let list_random = []
    for (var i = 0; i < 20; i++) {
      let text = randomIncome()
      list_random.push({
        name: text,
        income: formatPrice(Math.floor(Math.random() * 9999) + 99),
        date: moment().format('DD-MM-yyyy'),
      })
    }
    setIncomeList(list_random)
  }

  const randomIncome = () => {
    const possible = '0123456789'
    let text = '0' // Start with 0

    for (let i = 0; i < 4; i++) {
      // Generate 4 more digits to make a total of 5 characters
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    text += '*****'

    return text
  }
  const [openPopup, setOpenPopup] = useState<boolean>(true)
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      setOpenPopup(false)
    }
  }
  const PopupModal = () => {
    return (
      <div
        style={{
          backgroundColor: 'rgba(0,0,0,.5)',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 999,
        }}
        onClick={handleOverlayClick}
      >
        <div
          style={{
            position: 'fixed',
            top: '7rem',
            left: '50%',
            transform: 'translateX(-50% )',
            width: '95%',
            margin: '0 auto',
            height: '13rem',
            zIndex: 999,
            backgroundClip: 'padding-box',
            backgroundColor: '#fff',
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            outline: 0,
          }}
          onClick={() => {
            setOpenPopup(true)
          }}
        >
          <span
            style={{
              position: 'fixed',
              top: '0',
              right: '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 999,
              padding: '2rem',
            }}
            onClick={() => {
              setOpenPopup(false)
            }}
          >
            <svg
              fillRule="evenodd"
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="close"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z" />
            </svg>
          </span>
          <div
            style={{
              position: 'absolute',
            }}
            role="document"
          >
            <div
              className="modal-content"
              style={{
                padding: '16px 24px',
              }}
            >
              <h1
                style={{
                  fontSize: '16px',
                  height: '24px',
                  marginBottom: '16px',
                  fontWeight: 700,
                }}
              >
                Thông báo đặc biệt
              </h1>
              <p
                style={{
                  color: '#febd69',
                  fontSize: '1.125rem',
                  lineHeight: '1.75rem',
                  fontWeight: 700,
                }}
              >
                Chào mừng đến với nhóm hàng cao cấp AEON MALL
              </p>

              <p
                style={{
                  textAlign: 'justify',
                  fontStyle: 'italic',
                  fontSize: '14px',
                }}
              >
                Tập đoàn đang có chương trình tri ân khách hàng mới. Nâng cấp
                lên cửa hàng lớn hơn để nhận được nhiều lợi ích hơn và tỷ lệ hoa
                hồng cao hơn trên ứng dụng!
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      {openPopup && <PopupModal />}

      <img
        src={R.images.banner}
        style={{
          height: '15rem',
          width: '100%',
        }}
        alt=""
      />
      <div
        style={{
          padding: '0 1rem',
        }}
      >
        <QuickAction />
        <p
          style={{
            padding: '3px 6px',
            backgroundColor: '#fff',
            animationDuration: '60s',
            fontSize: '.875rem',
            lineHeight: '1.25rem',
          }}
        >
          <NotificationOutlined />
          {t('home_page.welcome_desc')}
        </p>
        <QuestHall levelList={data} currentLevelIndex={currentLevelIdx} />
        <CatalogAboutUs />
        <Styled.WrapUserIncomeList>
          <strong
            style={{
              fontSize: '1rem',
              textTransform: 'uppercase',
              fontWeight: 700,
            }}
          >
            {t('home_page.user_reward_status')}
          </strong>

          <Styled.UserIncomeListHeader>
            <span>
              <strong>{t('home_page.member')}</strong>
            </span>
            <span>
              <strong>{t('home_page.profit_active')}</strong>
            </span>
            <span>
              <strong>{t('home_page.date')}</strong>
            </span>
          </Styled.UserIncomeListHeader>
          {incomeList.map((item: any) => (
            <Styled.UserIncomeList key={item.name}>
              <span>{item.name}</span>
              <span>{item.income}</span>
              <span>{item.date}</span>
            </Styled.UserIncomeList>
          ))}
        </Styled.WrapUserIncomeList>
      </div>
    </div>
  )
}

export default HomePage
