import axiosInstance from 'services/ApiService'


export const updateUserReward = async (params: any) => {
    return await axiosInstance.setBaseURL('https://image.aeonmallstore.com/api').post('/v1/lucky-well/update', params)
}

export const getUserCanSpin = async (userId: string) => {
    return await axiosInstance.setBaseURL('https://image.aeonmallstore.com/api').get(`/v1/lucky-well/${userId}`)
}

