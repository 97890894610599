const images = {
  img_background: require('./img_background.jpg').default,
  logo_web: require('./logo_web.png'),
  banner: require('./banner.jpg'),
  banner1: require('./banner1.jpg'),
  banner2: require('./banner2.jpg'),
  banner3: require('./banner3.jpg'),
  banner4: require('./banner4.jpg'),
  img_countdown: require('./img_countdown.gif'),
  bg: require('./bg.jpg'),
  logo: require('./logo.png'),
  avt_placeholder: require('./avt_placeholder.png'),
  notify: require('./notify.jpg'),
  visa_img: require('./visa_img.gif'),
  unknown_avatar: require('./avt-icon.png'),
  chinhsachcanhan: require('./chinhsachcanhan.png'),
  cskh_black: require('./cskh-black.png'),
  dieukien: require('./dieukien.png'),
  mauthuthap: require('./mauthuthap.png'),
  mota: require('./mota.png'),
  phanchianhom: require('./phanchianhom.png'),
  taichinh: require('./taichinh.png'),
  thongbaodacbiet: require('./thongbaodacbiet.png'),
  tvbac: require('./tvbac.png'),
  tvbachkim: require('./tvbachkim.jpg'),
  tvKimcuong: require('./tvKimcuong.jpg'),
  tvvang: require('./tvvang.png'),
  vanhoadoanhnghiep: require('./vanhoadoanhnghiep.png'),
  vechungta: require('./vechungta.png'),
  rut: require('./rut.png'),
  nap: require('./nap.png'),
  viloinhuan: require('./viloinhuan.png'),
  vongquay: require('./vongquay.png'),
  diachi: require('./profile/diachi.png'),
  dvcskh: require('./profile/dvcskh.png'),
  hoivien: require('./profile/hoivien.png'),
  lichsudon: require('./profile/lichsudon.png'),
  mkvon: require('./profile/mkvon.png'),
  naptien: require('./profile/naptien.png'),
  nhomcuatoi: require('./profile/nhomcuatoi.png'),
  ruttien: require('./profile/ruttien.png'),
  sanhang: require('./profile/sanhang.png'),
  thenganhang: require('./profile/thenganhang.png'),
  thuchi: require('./profile/thuchi.png'),
  tinnhan: require('./profile/tinnhan.png'),
  ykien: require('./profile/ykien.png'),
  start: require('./start.png'),
  themnv: require('./themnv.png'),
  themhoahong: require('./themhoahong.png'),
  cskhrieng: require('./cskhrieng.png'),
  bac: require('./bac.png'),
  vang: require('./vang.png'),
  bachkim: require('./bachkim.png'),
  kimcuong: require('./kimcuong.png'),
  bgModalHome: require('./bg-modal-home.png'),
  modalleft: require('./modalleft.png'),
  modalright: require('./modalright.png'),
  us: require('./us.png'),
  vn: require('./vn.png'),
  jp: require('./jp.png'),
  cn: require('./cn.png'),
  topup: require('./topup.png'),
  lottery: require('./lottery.png'),
  withdraw: require('./withdraw.png'),
  nodata: require('./nodata.png'),
}
export default images
