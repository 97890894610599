import { LockOutlined } from '@ant-design/icons'
import { CHAT_LINK } from 'utils/constants'

import { Button, Carousel } from 'antd'
import R from 'assets'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import { useHistory } from 'react-router-dom'
import * as Styled from '../styled'
import { t } from 'i18next'

type Props = { data: any; isValid: boolean }

const contentStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  aspectRatio: '1.7',
  borderRadius: 3,
}
const levels = [
  'AEONMALL@INTERN',
  'AEONMALL@STAFF',
  'AEONMALL@LEADER',
  'AEONMALL@MONITOR',
]

const videos = [
  'https://aeonmaill-shoppingoninecom.dkmcom.vip/videos/video1.mp4',
  'https://aeonmaill-shoppingoninecom.dkmcom.vip/videos/video2.mp4',
  'https://aeonmaill-shoppingoninecom.dkmcom.vip/videos/video3.mp4',
  'https://aeonmaill-shoppingoninecom.dkmcom.vip/videos/video4.mp4',
]
const QuestComp: React.FC<Props> = ({ data, isValid }) => {
  const history = useHistory()
  return (
    <Styled.WarpQuest
      xs={24}
      lg={24}
      style={{
        padding: '0 6px',
        marginBottom: 10,
      }}
    >
      <div className="main-quest">
        <span
          // className="rank-name"font-bold text-xl
          style={{
            fontSize: '1.25rem',
            fontWeight: 700,
          }}
        >
          {data.price === 5000000
            ? levels[0]
            : data.price === 25000000
            ? levels[1]
            : data.price === 75000000
            ? levels[2]
            : levels[3]}
        </span>
        <span className="discount">{data?.commission_percent / 10}%</span>
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            height: '512px',
            width: '100%',
            objectFit: 'cover',
          }}
        >
          <source
            src={
              data.price === 5000000
                ? videos[0]
                : data.price === 25000000
                ? videos[1]
                : data.price === 75000000
                ? videos[2]
                : videos[3]
            }
            type="video/mp4"
          />
        </video>
        <div className="active">
          <div>
            <Styled.ButtonStyled
              style={{
                width: '100%',
                height: '3rem',
                fontSize: '1.125rem',
                marginTop: '2rem',
                lineHeight: '1.75rem',
                borderRadius: 6,
                border: 'none',
                backgroundColor: '#febd69',
                color: '#fff',
              }}
              onClick={() => {
                if (isValid)
                  history.push(`${ADMIN_ROUTER_PATH.ORDER}?key=${data.key}`)
                else {
                  history.push(ADMIN_ROUTER_PATH.SUPPORT)
                  window.open(CHAT_LINK)
                }
              }}
            >
              {isValid ? t('active') : t('wait_for_upgrade')}
            </Styled.ButtonStyled>
          </div>
        </div>
      </div>
    </Styled.WarpQuest>
  )
}
export default QuestComp
