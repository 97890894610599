import { Row } from 'antd'
import R from 'assets'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import history from 'utils/history'
import * as Styled from '../styled'
import { t } from 'i18next'

const CATALOG_LIST = [
  {
    url: 'https://en.wikipedia.org/wiki/Aeon_(disambiguation)',
    id: 1,
  },
  {
    id: 2,
    url: 'https://tuyendung.aeon.com.vn/about-us/lich-su-hinh-thanh/',
  },
  {
    id: 3,
    url: ADMIN_ROUTER_PATH.COMPANY_CULTURE,
  },
  {
    id: 4,
    url: 'https://www.investing.com/equities/aeon-mall-co-ltd',
  },
]

const CatalogAboutUs: React.FC = () => {
  return (
    <Row
      style={{
        marginBottom: '1.5rem',
        marginTop: '1rem',
      }}
      gutter={{ xs: 10, sm: 16, md: 24, lg: 32 }}
    >
      {CATALOG_LIST.map((item: any, index) => (
        <Styled.ItemCatalog
          xs={12}
          md={12}
          xl={6}
          onClick={() => {
            if (item.id === 3) {
              history.push(item.url)
            } else {
              window.open(item.url, '_blank')
            }
          }}
          key={index}
        >
          <div className="catalog-block">
            <span className="catalog-text">
              {item.id === 1
                ? t('home_page.about_us')
                : item.id === 2
                ? t('home_page.description')
                : item.id === 3
                ? t('home_page.develop')
                : t('home_page.finan')}
            </span>
            <span className="icon-arrow">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
              </svg>
            </span>
          </div>
        </Styled.ItemCatalog>
      ))}
    </Row>
  )
}
export default CatalogAboutUs
