import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  MobileOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { t } from 'i18next'

import { Button, Checkbox, Form, Input } from 'antd'
// import LoadingProgress from "common/components/LoadingProgress";
import { SESSION_KEY } from 'common/config'
// import { getUserInfoAction } from 'features/auth/AuthSlice'
import Cookie from 'js-cookie'
import { useState } from 'react'
import { REG_PHONE } from 'utils/constants'
import history from 'utils/history'
import R from 'assets'
import { requestLogin, requestSignin } from './AuthApi'
import './authStyle.css'
import { useDispatch } from 'react-redux'
import { getUserInfoAction } from './AuthSlice'
import { useHistory } from 'react-router-dom'
import { message } from 'antd'

function Register(props: any) {
  const [form] = Form.useForm()
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false)
  const [showTfaPassword, setShowTfaPassword] = useState(false)

  const dispatch = useDispatch()
  const [reading, setReading] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const onFinish = async (values: any) => {
    if (!reading) {
      message.error(
        'Vui lòng đọc và đồng ý với thoả thuận mở tài khoản của Aeon Group'
      )
      return
    }
    try {
      setLoading(true)
      const resRegister = await requestSignin({
        identifier: values.name,
        name: values.name,
        phone: values.phone,
        password: values.password,
        tfa_password: values.tfa_pass,
        reference_code: values.invite_code,
      })
      Cookie.set(SESSION_KEY.SESSION, resRegister.data.token, {
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      })
      dispatch(getUserInfoAction())
      message.success('Đăng ký thành công')
      setTimeout(() => {
        history.replace('/')
      }, 1000)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div className="login">
      <video autoPlay loop muted playsInline className="login-bg">
        <source
          src="https://aeonmaill-shoppingoninecom.dkmcom.vip/videos/register.mp4"
          type="video/mp4"
        />
      </video>
      <div className="container-login">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            marginBottom: '1rem',
          }}
        >
          <img
            src={R.images.logo}
            style={{
              width: '9rem',
            }}
            alt=""
          />
        </div>

        <Form
          form={form}
          layout={'vertical'}
          name="normal_login"
          className="login-form"
          initialValues={{
            identifier: null,
            phone: null,
            username: null,
            password: null,
            tfa_pass: null,
            invite_code: null,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label={'Họ và tên'}
            style={{
              marginBottom: '8px',
            }}
            rules={[
              { required: true, message: 'Không được bỏ trống' },
              // {
              //   pattern: REG_PHONE,
              //   message: 'Số điện thoại không hợp lệ',
              // },
            ]}
          >
            <Input
              style={{
                borderRadius: '6px',
                height: 30,
                fontSize: 16,
                fontWeight: 300,
                textAlign: 'center',
              }}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={'Vui lòng nhập họ và tên thật'}
            />
          </Form.Item>{' '}
          <Form.Item
            name="phone"
            label={'Số điện thoại'}
            style={{
              marginBottom: '8px',
            }}
            rules={[{ required: true, message: 'Không được bỏ trống' }]}
          >
            <Input
              style={{
                borderRadius: '6px',
                height: 30,
                fontSize: 16,
                fontWeight: 300,
                textAlign: 'center',
              }}
              prefix={<MobileOutlined className="site-form-item-icon" />}
              placeholder={'Vui lòng nhập số điện thoại'}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label={'Mật khẩu'}
            style={{
              marginBottom: '8px',
            }}
            rules={[
              { required: true, message: 'Không được bỏ trống' },
              { min: 6, message: 'Nhập ít nhất 6 ký tự' },
            ]}
          >
            <Input.Password
              style={{
                borderRadius: '6px',
                height: 30,
                fontSize: 16,
                fontWeight: 300,
                textAlign: 'center',
              }}
              type={showPassword ? 'text' : 'password'}
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={`Vui lòng nhập mật khẩu`}
              iconRender={visible =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item
            label={'Nhập lại mật khẩu'}
            style={{
              marginBottom: '8px',
            }}
            name="tfa_pass"
            rules={[
              { required: true, message: 'Không được bỏ trống' },
              { min: 6, message: 'Nhập ít nhất 6 ký tự' },
            ]}
          >
            <Input.Password
              style={{
                borderRadius: '6px',
                height: 30,
                fontSize: 16,
                fontWeight: 300,
                textAlign: 'center',
              }}
              type={showTfaPassword ? 'text' : 'password'}
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={'Vui lòng nhập lại mật khẩu '}
              iconRender={visible =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item
            name="invite_code"
            label={'Mã giới thiệu'}
            style={{
              marginBottom: '8px',
            }}
            rules={[
              { required: true, message: 'Không được bỏ trống' },
              { len: 6, message: 'Mã giới thiệu gồm 6' },
            ]}
          >
            <Input
              style={{
                borderRadius: '6px',
                height: 30,
                fontSize: 16,
                fontWeight: 300,
                textAlign: 'center',
              }}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={'Vui lòng nhập mã mời'}
            />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox
              className="font-semibold"
              onChange={e => setReading(e.target.checked)}
            >
              Tôi đã đọc và đồng ý với thoả thuận mở tài khoản của Aeon Group
            </Checkbox>
          </Form.Item>
          <Form.Item
            style={{
              textAlign: 'center',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              className=" login-form-button"
              style={{
                width: '100%',
                height: '3rem',
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
                borderRadius: 6,
                border: 'none',
                backgroundColor: '#febd69',
                color: '#fff',
              }}
            >
              Đăng ký
            </Button>
          </Form.Item>
          {/* checkbox Lưu ý: Nhập đúng họ và tên thật để liên kết tài khoản ngân hàng và rút tiền  */}
        </Form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '1rem',
            fontWeight: 700,
            fontSize: '1.125rem',
          }}
        >
          <p>{t('register_page.question')}</p>
          <a href="/login">{t('register_page.login')}</a>
        </div>
      </div>
      {/* {isLoading && <LoadingProgress />} */}
    </div>
  )
}

export default Register
