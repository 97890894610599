/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { LogoutOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Image, Layout, message, Row } from 'antd'
import R from 'assets'
import { ADMIN_ROUTER_PATH, SESSION_KEY } from 'common/config'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import Cookies from 'js-cookie'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosArrowForward } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'redux/store/store'
import { getListLevel } from 'services/api/CommonApi'
import Swal from 'sweetalert2'
import { CHAT_LINK } from 'utils/constants'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
const { Content } = Layout

const HeadTitle = ({ title }: { title: string }) => {
  return (
    <div
      style={{
        fontWeight: 'bold',
        padding: 10,
        marginTop: 10,
        fontSize: 20,
        border: 'none',
        backgroundColor: '#febd69',
      }}
    >
      {title}
    </div>
  )
}

const OptionSelectHorizontal = ({
  icon,
  title,
  to,
  redirect,
}: {
  icon: unknown
  title: string
  to: string
  redirect?: boolean
}) => {
  return (
    <div
      style={{
        padding: 5,
        paddingLeft: 12,
        marginTop: 5,
        fontSize: 14,
      }}
    >
      <Link
        to={to}
        style={{
          color: 'black',
          alignItems: 'center',
          display: 'flex',
          textAlign: 'center',
          fontWeight: 'bold',
        }}
        onClick={() => {
          if (redirect) {
            window.open(CHAT_LINK, '_blank')
          }
        }}
      >
        {icon}
        <div style={{ width: 5 }}></div>
        {title}
      </Link>
    </div>
  )
}

const ProfilePage: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const dispatch = useDispatch()
  const [listRank, setListRank] = useState<Array<any>>([])
  const [currentLevelIdx, setCurrentLevelIdx] = useState<number>(0)
  const { t, i18n } = useTranslation()
  const [, setCurrentLanguage] = useState<string>('vi')
  const getData = async () => {
    try {
      const resData = (await getListLevel()).data
      const ownLevel = userInfo?.level

      const currentLevelData = resData.find(
        (item: any) => item.key === ownLevel
      )
      let currentLvIdx =
        resData.indexOf(currentLevelData) === -1
          ? 0
          : resData.indexOf(currentLevelData)

      setListRank(resData)
      setCurrentLevelIdx(currentLvIdx)
    } catch (err) {}
  }
  useEffect(() => {
    dispatch(getUserInfoAction())
    getData()
  }, [])

  const fileInputRef = useRef<HTMLInputElement>(null)
  const [backgroundImage, setBackgroundImage] = useState<string>('')

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const front = event.target.files?.[0]
    const back = event.target.files?.[1]

    if (front) {
      const readerFront = new FileReader()
      readerFront.onloadend = () => {
        const base64ImageStringFront = `url(${readerFront.result})`
        setBackgroundImage(base64ImageStringFront)
        // Save to localStorage
        localStorage.setItem('bankFront', readerFront.result as string)
      }
      readerFront.readAsDataURL(front)
    }

    if (back) {
      const readerBack = new FileReader()
      readerBack.onloadend = () => {
        // Save to localStorage
        localStorage.setItem('bankBack', `${readerBack.result}`)
      }
      readerBack.readAsDataURL(back)
    }
  }

  useEffect(() => {
    const profileImage = localStorage.getItem('bankFront')
    if (profileImage) {
      setBackgroundImage(`url(${profileImage})`)
    }
  }, [])

  const defaultImage = `url(
    data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGgAAAAaCAYAAABb9hlrAAAAWElEQVRoge3RQQHAIBDAsGMasYB0dAwbfSQWss6+/5D1qWkTFCcoTlCcoDhBcYLiBMUJihMUJyhOUJygOEFxguIExQmKExQnKE5QnKA4QXGC4gTFCSqbmQcjtQLReG26xgAAAABJRU5ErkJggg==
  )`
  return (
    <Content
      style={{
        backgroundColor: '#fff',
        padding: '1rem',
      }}
    >
      <p className="text-xl">
        <strong>AEON MALL</strong>
      </p>
      <strong>
        {t('profile_page.phone_number')}: <span>{userInfo?.phone}</span>
      </strong>
      <p>
        <strong>
          {t('profile_page.balance')}
          <span
            style={{
              color: '#10b981',
            }}
          >
            {formatPrice(userInfo?.balance + userInfo?.frozen_balance)}
          </span>
        </strong>
      </p>
      <p style={{ color: '#9ca3af', fontSize: '16px', fontWeight: 600 }}>
        {t('profile_page.invitation_code')}:{' '}
        <span>{userInfo?.reference_code || userInfo?.phone}</span>
      </p>
      <div
        style={{
          marginTop: '1rem',
          marginBottom: '1rem',
        }}
      >
        <a
          href={CHAT_LINK}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            padding: '0.5rem 0.25rem',
            fontWeight: 'bold',
            border: '1px solid',
            color: 'black',
            borderColor: 'gray',
            marginRight: '0.25rem',
          }}
        >
          {t('profile_page.deposit')}
        </a>
        <a
          href={ADMIN_ROUTER_PATH.WITHDRAW_MONEY}
          style={{
            padding: '0.5rem 0.25rem',
            fontWeight: 'bold',
            border: '1px solid',
            color: 'black',
            borderColor: 'gray',
            marginRight: '0.25rem',
          }}
        >
          {t('profile_page.withdraw')}
        </a>
      </div>

      <div style={{ backgroundColor: 'white' }}>
        <HeadTitle title={t('profile_page.profile')} />
        <hr />

        <OptionSelectHorizontal
          to={`${ADMIN_ROUTER_PATH.MY_GROUP}?tabs=level_1`}
          title={t('profile_page.my_group')}
          icon={<img src={R.images.nhomcuatoi} style={{ height: 24 }} />}
        />
        <OptionSelectHorizontal
          to={ADMIN_ROUTER_PATH.BANK_CARD}
          title={t('profile_page.bank_card')}
          icon={<img src={R.images.thenganhang} style={{ height: 24 }} />}
        />

        <OptionSelectHorizontal
          to={ADMIN_ROUTER_PATH.MEMBER_RANK}
          title={t('profile_page.member_rank')}
          icon={<img src={R.images.hoivien} style={{ height: 24 }} />}
        />

        <OptionSelectHorizontal
          to={ADMIN_ROUTER_PATH.DELIVERY_ADDRESS}
          title={t('profile_page.delivery_address')}
          icon={<img src={R.images.diachi} style={{ height: 24 }} />}
        />
      </div>

      <div style={{ backgroundColor: 'white' }}>
        <HeadTitle title={t('profile_page.order_history')} />
        <hr />

        <OptionSelectHorizontal
          to={ADMIN_ROUTER_PATH.HISTORY}
          title={t('profile_page.order_history')}
          icon={<img src={R.images.lichsudon} style={{ height: 24 }} />}
        />
        <OptionSelectHorizontal
          to={ADMIN_ROUTER_PATH.ORDER}
          title={t('profile_page.order_begin')}
          icon={<img src={R.images.sanhang} style={{ height: 24 }} />}
        />
        {/* <OptionSelectHorizontal
          to={'#'}
          redirect={true}
          title={t('profile_page.visa_detail')}
          icon={<img src={R.images.nhomcuatoi} style={{ height: 24 }} />}
        /> */}
      </div>

      <div style={{ backgroundColor: 'white' }}>
        <HeadTitle title={t('profile_page.personal')} />
        <hr />

        <OptionSelectHorizontal
          to={ADMIN_ROUTER_PATH.HISTORY_TRANSACTION}
          title={t('profile_page.deposit_history')}
          icon={<img src={R.images.naptien} style={{ height: 24 }} />}
        />

        <OptionSelectHorizontal
          to={ADMIN_ROUTER_PATH.CHANGE_PASS}
          title={t('profile_page.origin_password')}
          icon={<img src={R.images.mkvon} style={{ height: 24 }} />}
        />
        <OptionSelectHorizontal
          to={ADMIN_ROUTER_PATH.DEPOSIT_PASS}
          title={t('profile_page.deposit_pass')}
          icon={<img src={R.images.mkvon} style={{ height: 24 }} />}
        />
      </div>

      <div style={{ backgroundColor: 'white' }}>
        <HeadTitle title={t('profile_page.contact')} />
        <hr />

        <OptionSelectHorizontal
          to={ADMIN_ROUTER_PATH.SUPPORT}
          title={t('profile_page.central_take_care_cumstomer')}
          icon={<img src={R.images.dvcskh} style={{ height: 24 }} />}
        />

        <OptionSelectHorizontal
          to={ADMIN_ROUTER_PATH.SUPPORT}
          title={t('profile_page.feedback')}
          icon={<img src={R.images.ykien} style={{ height: 24 }} />}
        />
      </div>
      <Divider style={{ margin: 5 }} />
      {/* <div
        style={{
          padding: 10,
          paddingLeft: 12,
          marginTop: 5,
          backgroundColor: 'white',
        }}
      >
        <a
          style={{
            color: 'black',
            fontSize: 16,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 5,
            fontWeight: 'bold',
          }}
        >
          <span>{t('profile_page.language')}</span>
          <p
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <select
              name="countries"
              onChange={e => {
                console.log(e.target.value)
                setCurrentLanguage(e.target.value)
                i18n.changeLanguage(e.target.value)
              }}
            >
              <option value="vi">Việt Nam</option>
              <option value="jp">Nhật Bản</option>
              <option value="en">USA</option>
              <option value="cn">Trung Quốc</option>
            </select>
            <IoIosArrowForward />
          </p>
        </a>
      </div> */}

      <p
        onClick={() => {
          // alert('Đăng xuất')
          const refWindow: any = window
          if (refWindow.$chatwoot) refWindow.$chatwoot.reset()
          Cookies.remove(SESSION_KEY.SESSION)
          message.success('Đăng xuất')
          history.replace('/')
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: 'bold',
          padding: 5,
          paddingLeft: 12,
          marginTop: 5,
          fontSize: 14,
        }}
      >
        <LogoutOutlined />{' '}
        <b
          style={{
            marginLeft: 5,
            fontWeight: 900,
            fontSize: '18px',
          }}
        >
          {t('profile_page.log_out')}
        </b>
      </p>
    </Content>
  )
}
export default ProfilePage
