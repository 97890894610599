import { Row } from 'antd'
import R from 'assets'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'redux/store/store'
const HeaderContainer: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const [selectedCountry, setSelectedCountry] = useState('vi')
  const [open, setOpen] = useState(false)
  const { t, i18n } = useTranslation()

  const handleSelect = (value: string) => {
    setSelectedCountry(value)
    setOpen(false)
    i18n.changeLanguage(value)
  }
  if (userInfo === null) return <></>
  const countries = [
    { value: 'vi', img: R.images.vn, alt: 'VN' },
    { value: 'en', img: R.images.us, alt: 'US' },
    { value: 'jp', img: R.images.jp, alt: 'JP' },
    { value: 'cn', img: R.images.cn, alt: 'CN' },
  ]
  return (
    <Row
      gutter={5}
      style={{
        position: 'fixed',
        backgroundColor: 'white',
        width: '100%',
        padding: '14px 6px',
        zIndex: 999,
        height: '56px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <img
        src={R.images.logo}
        alt=""
        style={{
          width: '7rem',
          padding: '0 0.75rem',
        }}
      />

      <div className="custom-dropdown">
        <div className="selected-option" onClick={() => setOpen(!open)}>
          <img
            style={{
              width: '2.5rem',
            }}
            src={countries.find(c => c.value === selectedCountry)?.img}
            alt={selectedCountry}
          />
        </div>
        {open && (
          <div className="options">
            {countries.map(country => (
              <div
                key={country.value}
                className="option"
                onClick={() => handleSelect(country.value)}
              >
                <img
                  style={{
                    width: '2.5rem',
                  }}
                  src={country.img}
                  alt={country.alt}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Row>
  )
}

export { HeaderContainer }
