import { Col, Spin } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { getImageFromServer } from 'utils/funcHelper'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import { requestHistory } from './api/ApiHistory'
import * as Styled from './styled'
import { t } from 'i18next'
import R from 'assets'

const HistoryPage: React.FC = () => {
  const [listOrder, setlistOrder] = useState<any>([])
  const [orderBlock, setOrderBlock] = useState<any>()

  const [params, setParams] = useState<any>({
    status: undefined,
    limit: 1000,
  })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get('status')
    if (myParam) {
      setParams({ ...params, status: myParam })
    } else {
      setParams({ ...params, status: undefined })
    }
  }, [window.location.search])

  useEffect(() => {
    getHistoryList()
  }, [params])

  const getHistoryList = async () => {
    let dataOrderBlock: Array<any> = []
    try {
      setIsLoading(true)
      const res = await requestHistory(params)
      const convertData = res.data?.map((item: any) => ({
        id: item?._id,
        image_url: item.product?.image,
        name_product: item.product?.name,
        price_product: item.meta?.value,
        commission: item.meta?.commission,
        status: item.status,
        is_block_create_order: item?.user?.is_block_create_order,
      }))

      if (params.status === 'Processing') {
        Cookies.set('block_order', JSON.stringify(convertData[0]))
      }
      if (params.status === 'Frozen') {
        let item: any = await Cookies.get('block_order')
        item = JSON.parse(item)
        if (item?.is_block_create_order == 0) {
          dataOrderBlock = [JSON.parse(item)]
          return
        }
      }
      setIsLoading(false)
      setlistOrder(dataOrderBlock?.length ? dataOrderBlock : convertData)
    } catch (error) {
      console.error('Exception ' + error)
    }
  }

  const getColorForTagStatus = (status: string) => {
    switch (status) {
      case 'Processing':
        return { color: 'var(--orange-1)', text: t('history_page.pending') }
      case 'Success':
        return { color: 'var(--green-1)', text: t('history_page.completed') }
      case 'Frozen':
        return { color: 'var(--blue-1)', text: t('history_page.frozen') }
      default:
        return { color: 'var(--orange-1)', text: t('history_page.pending') }
    }
  }

  return (
    <Styled.ContainerHistory>
      <div
        style={{
          borderRadius: '0.375rem',
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingTop: '0.25rem',
          paddingBottom: '0.25rem',
        }}
      >
        <h3
          style={{
            fontWeight: 700,
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
            marginBottom: '0',
          }}
        >
          {t('history_page.title')}
        </h3>
        <p
          style={{
            fontSize: '1.125rem',
          }}
        >
          {t('history_page.desc')}
        </p>
      </div>

      <Styled.ContainerTabs>
        <Styled.HeaderTabs>
          <Col
            span={6}
            className={`title-tabs ${!params.status ? 'active' : ''}`}
            onClick={() => {
              history.push({
                pathname: ADMIN_ROUTER_PATH.HISTORY,
              })
            }}
          >
            {t('history_page.all')}
          </Col>

          <Col
            span={6}
            className={`title-tabs ${
              params.status === 'Success' ? 'active' : ''
            }`}
            onClick={() => {
              history.push({
                pathname: ADMIN_ROUTER_PATH.HISTORY,
                search: '?status=Success',
              })
            }}
          >
            {t('history_page.success')}
          </Col>
          <Col
            span={6}
            className={`title-tabs ${
              params.status === 'Processing' ? 'active' : ''
            }`}
            onClick={() => {
              history.push({
                pathname: ADMIN_ROUTER_PATH.HISTORY,
                search: '?status=Processing',
              })
            }}
          >
            {t('history_page.processing')}
          </Col>
        </Styled.HeaderTabs>
      </Styled.ContainerTabs>
      <hr
        style={{
          color: 'var(--primary-color)',
          backgroundColor: 'var(--primary-color)',

          height: '0.5px',
          width: '95%',
          margin: '0 auto',
          border: 'none',
        }}
      />

      <Styled.ContentTab>
        {isLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '100vh',
            }}
          >
            <Spin size="large" />
          </div>
        )}
        {!isLoading &&
          listOrder?.map((item: any) => {
            let nameProduct = item.name_product
            return (
              <Styled.OrderBlock
                key={item.id}
                style={{
                  borderRadius: '10px',
                  backgroundColor: 'white',
                  color: 'black',
                  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
                  padding: '24px',
                }}
                onClick={() => {
                  if (item.status === 'Processing')
                    history.push({
                      pathname: ADMIN_ROUTER_PATH.DETAIL_ORDER,
                      state: item,
                    })
                }}
              >
                <div className="name-product">{nameProduct}</div>
                <div className="inner-info">
                  <img
                    alt="product"
                    src={getImageFromServer(item.image_url)}
                    // src={R.images.logo_web}
                    crossOrigin="anonymous"
                  />
                  <div className="price-block">
                    <div
                      style={{
                        fontSize: '12px',
                      }}
                    >
                      Lợi nhuận
                    </div>
                    <div style={{ color: 'var(--orange-1)', fontSize: 18 }}>
                      {formatPrice(item.commission)}
                    </div>
                  </div>
                  <div
                    className="tag-status"
                    style={{
                      backgroundColor: getColorForTagStatus(item.status).color,
                    }}
                  >
                    {getColorForTagStatus(item.status).text}
                  </div>
                </div>
                <div className="price-block">
                  <div
                    style={{
                      marginTop: '1rem',
                      fontSize: '12px',
                    }}
                  >
                    Giá trị đơn hàng
                  </div>
                  <div
                    style={{
                      color: 'var(--green-1)',
                      fontSize: 18,
                    }}
                  >
                    {formatPrice(item.price_product)}
                  </div>
                </div>
                <div className="main-status">
                  <div className="block-status">
                    <div
                      style={{
                        position: 'relative',
                        zIndex: 20,
                        borderRadius: '50%',
                        padding: '4px',
                        backgroundColor:
                          item.status === 'Processing' ||
                          item.status === 'Success'
                            ? '#1677ff'
                            : 'gray',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          left: '50%',
                          top: '160%',
                          height: '2rem',
                          width: '1px',
                          backgroundColor:
                            item.status === 'Success' ? '#1677ff' : 'gray',
                          transform: 'translateX(-50%)',
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        marginLeft: '1rem',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '16px',
                          color: 'black',
                        }}
                      >
                        {t('history_page.processing')}
                      </span>
                    </div>
                  </div>
                  <div className="block-status">
                    <div
                      style={{
                        position: 'relative',
                        zIndex: 20,
                        borderRadius: '50%',
                        padding: '4px',
                        backgroundColor:
                          item.status === 'Success' ? '#1677ff' : 'gray',
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          left: '50%',
                          top: '160%',
                          height: '2rem',
                          width: '1px',
                          backgroundColor: 'gray',
                          transform: 'translateX(-50%)',
                        }}
                      ></div>
                    </div>
                    <div
                      style={{
                        marginLeft: '1rem',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '16px',
                          color: item.status === 'Success' ? 'black' : 'gray',
                        }}
                      >
                        {t('history_page.completed')}
                      </span>
                    </div>
                  </div>
                  <div className="block-status">
                    <div
                      style={{
                        position: 'relative',
                        zIndex: 20,
                        borderRadius: '50%',
                        padding: '4px',
                        backgroundColor: 'gray',
                      }}
                    ></div>
                    <div
                      style={{
                        marginLeft: '1rem',
                      }}
                    >
                      <span
                        style={{
                          fontSize: '16px',
                          color: 'gray',
                        }}
                      >
                        {t('history_page.received')}
                      </span>
                    </div>
                  </div>
                </div>
              </Styled.OrderBlock>
            )
          })}
      </Styled.ContentTab>
    </Styled.ContainerHistory>
  )
}

export default HistoryPage
