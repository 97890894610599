import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import { AiOutlineHome } from 'react-icons/ai'
import { BsBoxSeam, BsPerson } from 'react-icons/bs'
import { GiClick } from 'react-icons/gi'
import { MdSupportAgent } from 'react-icons/md'
import history from 'utils/history'
import './style.css'
import * as Styled from './styled'
import { CHAT_LINK } from 'utils/constants'
import { useTranslation } from 'react-i18next'
import R from 'assets'
import { HistoryOutlined, ShoppingCartOutlined } from '@ant-design/icons'

const FooterContainer: React.FC = () => {
  const { t, i18n } = useTranslation()
  const MenuFooter = [
    {
      pathname: ADMIN_ROUTER_PATH.HOME,
      title: t('navigation_bottom.home'),
      icon: (
        <img
          src={R.images.logo}
          alt=""
          style={{
            width: '3.5rem',
          }}
        />
      ),
    },

    {
      pathname: ADMIN_ROUTER_PATH.HISTORY,
      title: t('navigation_bottom.history'),
      icon: (
        <HistoryOutlined
          style={{
            fontSize: '18px',
            transform: 'scaleX(-1)',
          }}
        />
      ),
    },
    {
      pathname: ADMIN_ROUTER_PATH.ORDER,
      title: t('navigation_bottom.order'),
      icon: <ShoppingCartOutlined style={styleIcon} />,
    },
    {
      pathname: ADMIN_ROUTER_PATH.SUPPORT,
      title: t('navigation_bottom.support'),
      icon: <MdSupportAgent style={styleIcon} />,
    },
    {
      pathname: ADMIN_ROUTER_PATH.PROFILE,
      title: t('navigation_bottom.profile'),
      icon: <BsPerson style={styleIcon} />,
    },
  ]

  return (
    <Styled.FooterBlock>
      <div className="main-footer">
        {MenuFooter?.map((item: any, index: number) => (
          <span
            key={index}
            className={`menu-nav ${
              item.pathname === window.location.pathname ? 'menu-active' : ''
            }`}
            onClick={() => {
              if (item.pathname == ADMIN_ROUTER_PATH.SUPPORT) {
                window.open(CHAT_LINK)
                return
              }
              history.push(item.pathname)
            }}
          >
            {item.icon} <p>{item.title}</p>
          </span>
        ))}
      </div>
    </Styled.FooterBlock>
  )
}

const styleIcon = { fontSize: '18px' }
export { FooterContainer }
