import { Button, message, Row, Spin } from 'antd'
import R from 'assets'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { getUserInfoAction } from 'features/auth/AuthSlice'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/store/store'
import { getUserAnalytic } from 'services/api/CommonApi'
import { IsBlockCreateOrder } from 'utils/constants'
import history from 'utils/history'
import { formatPrice } from 'utils/ruleForm'
import {
  requestLevelCategory,
  requestLevelDetail,
  requestLevelList,
} from '../home/Api/LevelApi'
import * as Styled from './styled'
import { t } from 'i18next'

const styleIcon = { fontSize: '35px', color: 'white' }

const ProfilePage: React.FC = () => {
  const [data, setData] = useState<any>({})
  const [dataLevel, setDataLevel] = useState<any>({})
  const [showProduct, setShowProduct] = useState<boolean>(false)
  const [analyticDetail, setAnalyticDetail] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // const [currentLevel, setCurrentLevel] = useState<any>();
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const addressData = userInfo?.order_address
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetchData = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const myParam = urlParams.get('key')
      try {
        if (myParam) {
          await getLevelDetail(myParam)
          await getLevelCategory(myParam)
        } else {
          await getLevelList()
        }
        await dispatch(getUserInfoAction())
        const analyticData = await getUserAnalytic()
        setAnalyticDetail(analyticData.data)
      } catch (error) {
        console.error('Error fetching data', error)
      } finally {
        setIsLoading(false)
        setShowProduct(true)
      }
    }
    fetchData()
    getRandomIncome()
    const intervalId = setInterval(getRandomIncome, 5000)
    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    const fetchCategory = async () => {
      if (dataLevel.key) {
        await getLevelCategory(dataLevel.key)
      }
    }
    fetchCategory()
  }, [dataLevel])

  const getLevelList = async () => {
    try {
      const res = await requestLevelList()
      const ownLevel = userInfo?.level
      let currentLevelData =
        res.data.find((item: any) => item.key === ownLevel) || res.data[0]
      setDataLevel(currentLevelData)
    } catch (error) {
      console.error('Exception', error)
    }
  }

  const getLevelDetail = async (myParam: any) => {
    try {
      const res = await requestLevelDetail(myParam)
      setDataLevel(res.data)
    } catch (error) {
      console.error('Exception', error)
    }
  }

  const getLevelCategory = async (myParam: any) => {
    try {
      const res = await requestLevelCategory(myParam)
      const category = res.data.map((item: any) => item.name)
      setData((prevData: any) => ({ ...prevData, category }))
    } catch (error) {
      console.error('Exception', error)
    }
  }

  const getCurrentLevel = () => {
    try {
    } catch (error) {
      console.error('Exception', error)
    }
  }

  const [income, setIncome] = useState<any>([])

  const getRandomIncome = () => {
    const text = randomIncome()
    const random_come = {
      phone: text,
      date: moment().format('h:mm:ss A'),
    }
    setIncome(random_come)
  }

  const randomIncome = () => {
    let phone = '0'
    const possible = '0123456789'
    for (let i = 1; i < 10; i++) {
      phone +=
        i >= 3 && i <= 5
          ? '*'
          : possible.charAt(Math.floor(Math.random() * possible.length))
    }
    return phone
  }

  const num_completed_orders = (data: any) => {
    if (data.price === 5000000) return 60
    if (data.price === 25000000) return 80
    if (data.price === 75000000) return 100
    return 120
  }
  const levels = [
    'AEONMALL@INTERN',
    'AEONMALL@STAFF',
    'AEONMALL@LEADER',
    'AEONMALL@MONITOR',
  ]
  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spin size="large" />
      </div>
    )
  }

  return (
    <div style={{ padding: '0 12px' }}>
      <Styled.WhiteBox
        style={{
          padding: '1rem',
          marginTop: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <img src={R.images.banner} width={80} alt="" />
          <span
            style={{
              fontSize: '1.25rem',
              lineHeight: '1.75rem',
            }}
          >
            {dataLevel.price === 5000000
              ? levels[0]
              : dataLevel.price === 25000000
              ? levels[1]
              : dataLevel.price === 75000000
              ? levels[2]
              : levels[3]}
          </span>
        </div>
        <div style={{ fontWeight: 600, margin: '1rem 0' }}>
          {t('home_page.profit_active')}:{dataLevel.commission_percent}%
        </div>
        <img alt="gif" src={R.images.banner} />
        {showProduct && (
          <div
            style={{
              marginTop: '1rem',
            }}
          >
            <div
              onClick={() => {
                if (!addressData) {
                  message.error('Vui lòng cập nhật địa chỉ trong "Tài khoản"')
                  return
                }
                if (
                  userInfo?.is_block_create_order === IsBlockCreateOrder.Blocked
                ) {
                  message.error(
                    'Chúc mừng bạn đã trúng 1 đơn hàng thưởng may mắn. Vui lòng liên hệ CSKH để được hỗ trợ'
                  )
                  return
                }

                if (
                  dataLevel.order_quantity_per_day ===
                  analyticDetail?.count_order_today
                ) {
                  message.error(
                    'Số đơn trong ngày đã tới giới hạn. Vui lòng liên hệ quản lý để nâng cấp giới hạn đơn trong ngày'
                  )
                }
                console.log(userInfo?.level, dataLevel?.key)
                history.push({
                  pathname: ADMIN_ROUTER_PATH.BILL,
                  state: {
                    own_level: userInfo?.level,
                    first_level: dataLevel?.key,
                  },
                })
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className=" login-form-button"
                style={{
                  width: '100%',
                  height: '3rem',
                  fontSize: '1.125rem',
                  lineHeight: '1.75rem',
                  borderRadius: 6,
                  border: 'none',
                  backgroundColor: '#febd69',
                  color: '#fff',
                }}
              >
                {t('order_page.get_order')}
              </Button>
            </div>
          </div>
        )}
      </Styled.WhiteBox>
      <Styled.WhiteBox
        style={{
          marginTop: '1rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <div className="icon">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M332.8 320h38.4c6.4 0 12.8-6.4 12.8-12.8V172.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v134.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V76.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v230.4c0 6.4 6.4 12.8 12.8 12.8zm-288 0h38.4c6.4 0 12.8-6.4 12.8-12.8v-70.4c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v70.4c0 6.4 6.4 12.8 12.8 12.8zm96 0h38.4c6.4 0 12.8-6.4 12.8-12.8V108.8c0-6.4-6.4-12.8-12.8-12.8h-38.4c-6.4 0-12.8 6.4-12.8 12.8v198.4c0 6.4 6.4 12.8 12.8 12.8zM496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z" />
            </svg>
          </div>
          <h1
            style={{
              fontSize: '1.25rem',
              lineHeight: '1.75rem',
            }}
          >
            {t('order_page.analytic_detail')}
          </h1>
        </div>
        <Row style={{ width: '100%' }}>
          <Styled.InfoBlock xs={24} md={12}>
            <p>
              {t('order_page.num_completed_orders')}
              {': '}
              <strong>
                {analyticDetail?.count_order_today}/
                {dataLevel.order_quantity_per_day}
              </strong>
            </p>
          </Styled.InfoBlock>
          <Styled.InfoBlock xs={24} md={12}>
            <p>{t('order_page.balance')}</p>
            <span>
              {formatPrice(
                analyticDetail?.balance + analyticDetail?.frozen_balance_v2
              )}
            </span>
          </Styled.InfoBlock>

          <Styled.InfoBlock xs={24} md={12}>
            <p>{t('order_page.reward_today')}</p>
            <span>
              {formatPrice(
                Number.parseFloat(analyticDetail?.total_commission_today)
              )}
            </span>
          </Styled.InfoBlock>
          <Styled.InfoBlock xs={24} md={12}>
            <p>{t('order_page.frozen_balance')}</p>
            <span>
              {dataLevel.order_quantity_per_day -
                analyticDetail?.count_order_today}{' '}
            </span>
          </Styled.InfoBlock>
        </Row>
      </Styled.WhiteBox>
      <Styled.WhiteBox style={{ marginTop: '1rem', textAlign: 'center' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <div className="icon">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 352 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M176 80c-52.94 0-96 43.06-96 96 0 8.84 7.16 16 16 16s16-7.16 16-16c0-35.3 28.72-64 64-64 8.84 0 16-7.16 16-16s-7.16-16-16-16zM96.06 459.17c0 3.15.93 6.22 2.68 8.84l24.51 36.84c2.97 4.46 7.97 7.14 13.32 7.14h78.85c5.36 0 10.36-2.68 13.32-7.14l24.51-36.84c1.74-2.62 2.67-5.7 2.68-8.84l.05-43.18H96.02l.04 43.18zM176 0C73.72 0 0 82.97 0 176c0 44.37 16.45 84.85 43.56 115.78 16.64 18.99 42.74 58.8 52.42 92.16v.06h48v-.12c-.01-4.77-.72-9.51-2.15-14.07-5.59-17.81-22.82-64.77-62.17-109.67-20.54-23.43-31.52-53.15-31.61-84.14-.2-73.64 59.67-128 127.95-128 70.58 0 128 57.42 128 128 0 30.97-11.24 60.85-31.65 84.14-39.11 44.61-56.42 91.47-62.1 109.46a47.507 47.507 0 0 0-2.22 14.3v.1h48v-.05c9.68-33.37 35.78-73.18 52.42-92.16C335.55 260.85 352 220.37 352 176 352 78.8 273.2 0 176 0z" />
            </svg>
          </div>
          <h1
            style={{
              fontSize: '1.25rem',
              lineHeight: '1.75rem',
            }}
          >
            {t('order_page.explain')}
          </h1>
        </div>

        <Styled.Note style={{ textAlign: 'left' }}>
          {t('order_page.explain_desc')}
        </Styled.Note>
      </Styled.WhiteBox>
    </div>
  )
}

export default ProfilePage
