import PageTitle from 'common/components/PageTitle'
import React from 'react'
import { Button, Form, Input, message } from 'antd'
import * as Styled from './styled'
import { useAppSelector } from 'redux/store/store'
import { formatPrice } from 'utils/ruleForm'
import { requestConductTransaction } from 'services/api/TransactionApi'
import history from 'utils/history'
import { ADMIN_ROUTER_PATH } from 'common/config'
import { t } from 'i18next'

const WithdrawMoneyPage: React.FC = () => {
  const { userInfo } = useAppSelector(state => state.AuthReducer)

  const [form] = Form.useForm()

  const onFinish = async (values: any) => {
    const resData = await requestConductTransaction({
      type: 'CashOut',
      amount: Number(values.amount),
      tfa_password: values.pass,
    })

    if (resData.data) {
      message.success('Yêu cầu rút tiền thành công')
      history.push(ADMIN_ROUTER_PATH.HISTORY_TRANSACTION)
    } else {
      message.error(
        resData.message[0] + 'Vui lòng liên hệ CSKH để được giải quyết'
      )
    }
  }
  function hashMiddleFiveChars(str: string) {
    if (str === null || str === undefined) return ''
    if (str.length < 5) {
      return str // If the string is too short, return it as is
    }

    const start = Math.floor((str.length - 5) / 2)
    const end = start + 5

    return str.slice(0, start) + '*****' + str.slice(end)
  }

  console.log('userInfo', userInfo)
  return (
    <div>
      <Styled.WrapCardCenter>
        <Styled.CardForm>
          <span
            style={{
              fontSize: 20,
              fontWeight: 'normal',
              paddingLeft: 12,
            }}
          >
            Rút tiền
          </span>
          <div
            style={{
              fontSize: 16,
              fontWeight: 'normal',
            }}
          >
            <p>
              {t('withdraw_page.balance')}:{' '}
              <b>{formatPrice(userInfo?.balance)}</b>
            </p>
            <p>
              {t('withdraw_page.bank_number')}:{' '}
              <b>{hashMiddleFiveChars(userInfo?.bank?.bank_number)}</b>
            </p>
            <p>
              {t('withdraw_page.bank_account')}:{' '}
              <b>{userInfo?.bank?.full_name}</b>
            </p>
            <p>
              {t('withdraw_page.bank_name')}: <b>{userInfo?.bank?.bank_name}</b>
            </p>
            <h3
              className="text-lg"
              style={{
                fontWeight: 'bold',
                fontSize: '1.125rem',
                lineHeight: '1.75rem',
              }}
            >
              Số tiền rút
            </h3>
          </div>
          <Form
            form={form}
            initialValues={{
              amount: null,
              pass: null,
            }}
            onFinish={onFinish}
          >
            <div className="ant-input__label">Số tiền rút</div>
            <Form.Item
              name={'amount'}
              rules={[{ required: true, message: 'Không được bỏ trống' }]}
            >
              <Input
                placeholder="Số tiền rút"
                prefix={''}
                style={{
                  border: 'none',
                }}
                // className="ant-input__money-custom"
              />
            </Form.Item>
            {/* <span className="ant-input__wrapper">
              <span className="ant-input__prefix">$</span>
              <span className="ant-input__suffix">USD</span>
            </span> */}
            <div
              style={{
                display: 'flex',
                margin: '10px 0',
                alignItems: 'center',
                fontSize: '16px',
              }}
            >
              <div style={{ marginRight: 15 }}>
                Tổng tiền:{' '}
                {formatPrice(userInfo?.balance + userInfo?.frozen_balance)}
              </div>
              <div
                onClick={() => {
                  form.setFieldsValue({
                    amount: formatPrice(
                      userInfo?.balance + userInfo?.frozen_balance
                    )
                      .replaceAll(',', '')
                      .replace('VND', ''),
                  })
                }}
                style={{
                  cursor: 'pointer',
                  padding: '0 5px',
                  borderRadius: '3px',
                  background: '#fff',
                  border: '1px solid rgba(99, 99, 99, 0.2)',
                }}
              >
                Rút tất cả
              </div>
            </div>
            <div className="ant-input__label">Mật khẩu rút tiền</div>
            <Form.Item
              name={'pass'}
              rules={[{ required: true, message: 'Không được bỏ trống' }]}
            >
              <Input
                type={'password'}
                placeholder="Mật khẩu rút tiền"
                style={{
                  border: 'none',
                }}
              />
            </Form.Item>
            {/* <div style={{ marginTop: 10 }} className="warning-text">
              Lời nhắc nhở: Vui lòng kiểm tra kỹ thông tin thanh toán
            </div>
            <div className="warning-text">Việc rút tiền trừ phí xử lý 0%</div> */}
            <Form.Item
              style={{
                marginBottom: 0,
                marginTop: 15,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  width: '100%',
                  height: '3rem',
                  lineHeight: '1.75rem',
                  borderRadius: 6,
                  border: 'none',
                  backgroundColor: '#febd69',
                  color: '#fff',
                }}
              >
                <span
                  style={{
                    fontSize: '1.125rem',
                    fontWeight: 500,
                  }}
                >
                  Rút ngay
                </span>
              </Button>
            </Form.Item>
          </Form>
        </Styled.CardForm>
      </Styled.WrapCardCenter>
    </div>
  )
}
export default WithdrawMoneyPage
