import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
// import LoadingProgress from "common/components/LoadingProgress";
import { SESSION_KEY } from 'common/config'
// import { getUserInfoAction } from 'features/auth/AuthSlice'
import { message } from 'antd'
import R from 'assets'
import { t } from 'i18next'
import Cookie from 'js-cookie'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { requestLogin } from './AuthApi'
import { getUserInfoAction } from './AuthSlice'
import './authStyle.css'

function Login(props: any) {
  const [form] = Form.useForm()
  const history = useHistory()

  const [showPassword, setShowPassword] = useState(false)
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)

  const onFinish = async (values: any) => {
    try {
      setLoading(true)
      const resLogin = await requestLogin({
        identifier: values.phone,
        password: values.password,
      })

      Cookie.set(SESSION_KEY.SESSION, resLogin.data.token, {
        expires: new Date(Date.now() + 24 * 60 * 60 * 1000),
      })
      dispatch(getUserInfoAction())
      message.success('Đăng nhập thành công')
      setTimeout(() => {
        history.replace('/')
      }, 1000)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div>
      <div className="login">
        <video autoPlay loop muted playsInline className="login-bg">
          <source
            src="https://aeonmaill-shoppingoninecom.dkmcom.vip/videos/register.mp4"
            type="video/mp4"
          />
        </video>
        <div className="container-login">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              position: 'relative',
              marginBottom: '1rem',
            }}
          >
            <img
              src={R.images.logo}
              style={{
                width: '9rem',
              }}
              alt=""
            />
          </div>
          <Form
            form={form}
            layout={'vertical'}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
              phone: null,
              password: null,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="phone"
              label={t('login_page.phone')}
              rules={[
                { required: true, message: t('login_page.message_not_empty') },
              ]}
              style={{
                marginBottom: '8px',
              }}
            >
              <Input
                style={{
                  borderRadius: '6px',
                  height: 30,
                  fontSize: 16,
                  fontWeight: 300,
                  textAlign: 'center',
                }}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t('login_page.phone')}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={t('login_page.password')}
              rules={[
                { required: true, message: t('login_page.message_not_empty') },
                { min: 6, message: t('login_page.password_too_short') },
              ]}
            >
              <Input.Password
                style={{
                  borderRadius: '6px',
                  height: 30,
                  fontSize: 16,
                  fontWeight: 300,
                }}
                prefix={<LockOutlined className="site-form-item-icon" />}
                type={showPassword ? 'text' : 'password'}
                placeholder={t('login_page.password')}
                iconRender={visible =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>

            <Form.Item
              style={{
                textAlign: 'center',
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                className=" login-form-button"
                style={{
                  width: '100%',
                  height: '3rem',
                  fontSize: '1.125rem',
                  lineHeight: '1.75rem',
                  borderRadius: 6,
                  border: 'none',
                  backgroundColor: '#febd69',
                  color: '#fff',
                }}
              >
                {t('login_page.login')}
              </Button>
            </Form.Item>
          </Form>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '1rem',
              fontWeight: 700,
              fontSize: '1.125rem',
            }}
          >
            <p>{t('login_page.question')}</p>
            <a href="/register">{t('login_page.register')}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
