import R from 'assets'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React from 'react'
import { CHAT_LINK } from 'utils/constants'
import history from 'utils/history'

const QuickAction: React.FC = () => {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        marginTop: '2rem',
        gap: '1rem',
        marginBottom: '0.5rem',
      }}
    >
      <div
        onClick={() => {
          history.push(ADMIN_ROUTER_PATH.SUPPORT)
          window.open(CHAT_LINK)
        }}
        style={{
          background: 'white',
          padding: '8px',
          borderRadius: '0.375rem',
        }}
      >
        <img
          style={{
            width: '100%',
          }}
          src={R.images.topup}
          alt=""
        />
      </div>
      <div
        onClick={() => {
          history.push(ADMIN_ROUTER_PATH.WHEEL)
          // window.open(CHAT_LINK)
        }}
        style={{
          background: 'white',
          padding: '8px',
          borderRadius: '0.375rem',
        }}
      >
        <img
          style={{
            width: '100%',
          }}
          src={R.images.lottery}
          alt=""
        />
      </div>
      <div
        onClick={() => {
          history.push(ADMIN_ROUTER_PATH.WITHDRAW_MONEY)
        }}
        style={{
          background: 'white',
          padding: '8px',
          borderRadius: '0.375rem',
        }}
      >
        <img
          style={{
            width: '100%',
          }}
          src={R.images.withdraw}
          alt=""
        />
      </div>
    </div>
  )
}

export default QuickAction
