import { Button, Form, Input, message, Select } from 'antd'
import { ADMIN_ROUTER_PATH } from 'common/config'
import React, { useEffect } from 'react'
import { useAppSelector } from 'redux/store/store'
import { requestUpdateBankInfor } from 'services/api/AccountApi'
import { UserBankNames } from 'utils/constants'
import history from 'utils/history'
import * as Styled from './styled'

const BankCardPage: React.FC = () => {
  const { Option } = Select
  const { userInfo } = useAppSelector(state => state.AuthReducer)
  const onFinish = async (values: any) => {
    const resData = (
      await requestUpdateBankInfor({
        full_name: values.name,
        phone: values.phone,
        bank_number: values.bankNumber,
        bank_name: values.bankName,
      })
    ).data

    if (resData.bank) {
      message.success('Cập nhật thông tin thẻ ngân hàng thành công')
      history.push(ADMIN_ROUTER_PATH.PROFILE)
    }
  }

  const replaceAt = (replacement: any) => {
    let str = replacement.split('')
    str = str.map((element: any, index: any) => {
      if (index > str.length - 4) return (element = '*')
      return element
    })

    return str.join('')
  }
  if (!userInfo) return null
  return (
    <Styled.Container>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          name: userInfo?.bank ? userInfo?.bank?.full_name : '',
          bankNumber: userInfo?.bank
            ? replaceAt(userInfo?.bank?.bank_number)
            : '',
          bankName: userInfo?.bank ? userInfo?.bank?.bank_name : '',
          phone: userInfo?.bank ? replaceAt(userInfo?.bank?.phone) : '',
        }}
        onFinish={onFinish}
        autoComplete="off"
        style={{ marginTop: '0.5rem' }}
      >
        <Form.Item
          label="Họ và tên chủ tài khoản"
          name="name"
          style={{
            marginBottom: '5px',
          }}
          rules={[{ required: true, message: 'Không được bỏ trống' }]}
        >
          <Input
            disabled={userInfo?.bank?.full_name}
            placeholder="Họ tên"
            style={{
              border: 'none',
              background: 'white',
              marginTop: '-0.5rem',
            }}
          />
        </Form.Item>
        <Form.Item
          label="Số tài khoản"
          name="bankNumber"
          style={{
            marginBottom: '5px',
          }}
          rules={[{ required: true, message: 'Không được bỏ trống' }]}
        >
          <Input
            disabled={userInfo?.bank?.bank_number}
            placeholder="Số tài khoản"
            style={{
              border: 'none',
              background: 'white',
              marginTop: '-0.5rem',
            }}
          />
        </Form.Item>
        <Form.Item
          name={'bankName'}
          label="Tên ngân hàng"
          style={{
            marginBottom: '5px',
          }}
          rules={[{ required: true, message: 'Không được bỏ trống' }]}
        >
          <Select
            disabled={userInfo?.bank?.bank_name}
            showSearch
            placeholder="Tên ngân hàng"
            optionFilterProp="children"
            onChange={() => {}}
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {Object.values(UserBankNames).map(bank => (
              <Option key={bank} value={bank}>
                {bank}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <p style={{ color: 'red', textAlign: 'center' }}>
          Lưu ý: Để đảm bảo quyền lợi của bạn, vui lòng điền đúng địa chỉ tài
          khoản ngân hàng, nếu không, việc chuyển khoản sẽ không thành công! Vui
          lòng đọc kỹ!
        </p>
        {!userInfo?.bank ? (
          <Form.Item>
            <Button type="primary" htmlType="submit">
              CÀI ĐẶT THÔNG TIN
            </Button>
          </Form.Item>
        ) : null}
      </Form>
    </Styled.Container>
  )
}
export default BankCardPage
